import React, { memo } from "react";
import type { ReactElement } from "react";
import { HashLink } from "react-router-hash-link";
import Button from "./Button";

interface NavigationListProps {
  onClick?: () => void;
}

function NavigationList({ onClick }: NavigationListProps): ReactElement {
  return (
    <>
      <Button>
        <HashLink to="#about" onClick={onClick}>
          about
        </HashLink>
      </Button>
      <Button>
        <HashLink to="#menu" onClick={onClick}>
          menu
        </HashLink>
      </Button>
      <Button>
        <a href="https://order.angust.com/locations/angust">
          order
        </a>
      </Button>
      <Button>
        <HashLink to="#career" onClick={onClick}>
          career
        </HashLink>
      </Button>
      <Button>
        <HashLink to="#contact-locations" onClick={onClick}>
          contact
        </HashLink>
      </Button>
    </>
  );
}

export default memo(NavigationList);
